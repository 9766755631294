<template>
    <div class="row">
        <div class="col-12 mb-1">
            <label class="form-label">Ad</label>
            <input type="text" class="form-control" v-model="value.Name" placeholder="Ad" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Soyad</label>
            <input type="text" class="form-control" v-model="value.Surname" placeholder="Soyad" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">E-Posta</label>
            <input type="text" class="form-control" v-model="value.Email" placeholder="E-Posta" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Telefon</label>
            <input type="text" class="form-control" v-model="value.PhoneNumber" placeholder="Telefon" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Plasiyer Kodu</label>
            <input type="text" class="form-control" v-model="value.SalesPersonCode" placeholder="Plasiyer Kodu" />
        </div>
        <div class="col-12 mb-1">
            <label class="form-label">Rol</label>
            <v-select-list :options="roles" v-model="role" placeholder="Rol" label="Name"></v-select-list>
        </div>
        
    </div>
</template>

<script>
import { RoleApi } from '@/api/role'
import { UserApi } from '@/api/user'
export default {
    props:{
        value:Object
    },
    data(){
        return{
            roles:[],
            users:[]
        }
    },
    mounted(){
        this.getRoles()
       
    },
    methods:{
        getRoles(){
            RoleApi.List().then(res=>{
                this.roles = res.data.Data
            })
        }
    },
    computed:{
        role:{
            get:function(){
                return this.roles.find(r=>r.Id == this.value.RoleGroupId)
            },
            set:function(value){
                this.value.RoleGroupId = value?.Id || null
            }
        }
    }
}
</script>

<style>

</style>