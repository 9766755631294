import axios from 'axios'

export const RoleApi = {
    async List(){
        const response = await axios.get('/api/role/list')
        return response
    },
    async Get(data){
        const response = await axios.get(`/api/role/get/${data}`)
        return response
    },
    async CreateOrUpdate(update,data){
        const response = await axios.post(update? '/api/role/update':'/api/role/create',data)
        return response
    },
    async Delete(data){
        const response = await axios.post(`/api/role/delete/${data}`)
        return response
    }
}